import * as React from "react";
import styled from "@emotion/styled";
import {Button, message} from "antd";
import {GithubOutlined} from "@ant-design/icons"
import {useEffect} from "react";
import auth from "../../../utils/auth";
import {useDispatch} from "react-redux";
import config from "../../../config";

const Container = styled.div`
  margin: 0 auto;
  width: ${(props: any) => props.width ?? 400}px;
  background-color: #ffffff;
  box-shadow: 0 8px 10px -5px rgba(0,0,0,0.08), 0px 16px 24px 2px rgba(0,0,0,0.04), 0px 6px 30px 5px rgba(0,0,0,0.05);
  border-radius: 8px;
`

const Title = styled.div`
  padding-top: 50px;
  line-height: 22px;
  text-align: center;
  font-size: 20px;
  font-family: PingFang SC-Bold, PingFang SC,sans-serif;
  font-weight: bold;
  color: rgba(0,0,0,0.8);
`

const Body = styled.div`
  padding: 40px;
`

const Inputs = styled.div`
  margin-bottom: 32px;
  &>*:not(:last-child) {
    margin-bottom: 16px;
  }
`

const Append = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`

const AppendLink = styled.a`
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  text-decoration: none;
`

const OtherSignTypesTitle = styled.div`
  margin-top: 40px;
  height: 22px;
  text-align: center;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  & span {
    padding: 0 5px;
    background-color: #ffffff;
  }
`

const OtherSignTypesTitleLine = styled.div`
  margin-top: -11px;
  border-bottom: 1px dashed #000000;
`

const OtherSignTypes = styled.div`
  margin-top: 21px;
  display: flex;
  justify-content: space-around;
`

const OtherSignType = styled.a`
  text-align: center;
  color: #000;
  text-decoration: none;
  &:hover {
    color: #555;
  }
`

const OtherSignTypeName = styled.div`
  margin-top: 7px;
  line-height: 22px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.4);
`

const SignCard = (props) => {
  const {
    width = 400,
    title,
    inputs,
    links,
    buttonText,
    append,
    location,
    children,
    onSubmit = () => {},
    ...containerProps
  } = props
  const dispatch = useDispatch()
  useEffect(() => {
    auth.clearToken()
    auth.load(dispatch) // todo: set token in store
  }, [props.location])
  return (<>
    <Container {...{width, ...containerProps}}>
      <Title>{title}</Title>
      <Body>
        <Inputs>
          {inputs}
        </Inputs>
        <Button onClick={onSubmit} type='primary' size='large' block style={{background: '#000000', borderColor: '#000000'}}>
          提交
        </Button>
        <Append>
          {append}
          {links?.map(link => (
            <AppendLink href={link.href} key={link.text}>{link.text}</AppendLink>
          ))}
        </Append>
        {children}
        <OtherSignTypesTitle>
          <span>其他登录方式</span>
          <OtherSignTypesTitleLine/>
        </OtherSignTypesTitle>
        <OtherSignTypes>
          {/*<OtherSignType>*/}
          {/*  <GithubOutlined style={{fontSize: 34}} />*/}
          {/*  <OtherSignTypeName>GitHub登录</OtherSignTypeName>*/}
          {/*</OtherSignType>*/}
          {/*<OtherSignType>*/}
          {/*  <GithubOutlined style={{fontSize: 34}} />*/}
          {/*  <OtherSignTypeName>GitHub登录</OtherSignTypeName>*/}
          {/*</OtherSignType>*/}
          <OtherSignType
            // href={`https://github.com/login/oauth/authorize?client_id=${config.github.clientId}&redirect_uri=${location.origin}/oauth/github`}
            onClick={() => {
              message.warning("未开放")
            }}
          >
            <GithubOutlined style={{fontSize: 34}} />
            <OtherSignTypeName>GitHub登录</OtherSignTypeName>
          </OtherSignType>
        </OtherSignTypes>
      </Body>
    </Container>
  </>)
}

export default SignCard
