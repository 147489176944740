import * as React from "react";
import PageWrapper from "../../components/pages/PageWrapper";
import SignCard from "../../components/pages/sign/SignCard";
import {Form, FormInstance, Input, message} from "antd";
import Seo from "../../components/seo"
import {useEffect, useRef, useState,} from "react";
import request from "../../utils/request";
import {useDispatch} from "react-redux";
import auth from "../../utils/auth";

const SignIn = ({location}) => {

  const ref = useRef<FormInstance>()
  const dispatch = useDispatch()
  const [account, setAccount] = useState('')
  const [type, setType] = useState<'phone'|'email'>('phone')

  useEffect(() => {
    if (account.match(/^.*@.*$/)) {
      setType('email')
    } else {
      setType('phone')
    }
  }, [account])

  const handleValuesChange = values => {
    if ('account' in values) {
      setAccount(values.account)
    }
  }
  const handleFinish = values => {
    const data = {
      ...values
    }
    data[type] = account
    request(type == 'phone' ? "/login" : '/login/email', {
      method: 'POST',
      data,
    }).then(rsp => {
      rsp.json().then(json => {
        if (!json.success) {
          message.error(json.message ?? 'error')
        } else {
          auth.setToken(json.data.token)
          auth.load(dispatch).then(() => {
            auth.redirect()
          })
        }
      })
    }).catch(console.error)
  }
  return (<>
    <PageWrapper>
      <SignCard
        title='密码登录'
        buttonText="登录"
        location={location}
        inputs={(
          <Form
            ref={ref}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
          >
            <Form.Item
              name='account'
              required={false}
              rules={[{required: true, message: '请输入手机号或邮箱'}]}
            >
              <Input size="large" placeholder="请输入手机号或邮箱" />
            </Form.Item>
            <Form.Item
              name='password'
              required={false}
              rules={[{required: true, message: '请输入密码'}]}
            >
              <Input.Password size="large" placeholder="请输入密码" />
            </Form.Item>
          </Form>
        )}
        links={[
          {
            href: "/sign/sms_in",
            text: '验证码登录',
          },
          {
            href: "/sign/on",
            text: '注册账号'
          }
        ]}
        onSubmit={() => ref.current?.submit()}
      >
      </SignCard>
    </PageWrapper>
  </>)
}

export default SignIn

export const Head = () => <Seo title='GitWork-账号登录'/>
